import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

function CompanyReport({passNavbarFlag,passFooterFlag, passPrevFooterFlag }) {
    const [blurBG, setBlurBG] = useState(false)
    const [check, setCheck] = useState(false)

    const moaUrl = "https://ik.imagekit.io/qljqw3tvn/Rashi/MOA_cH8Q_nLUVp.jpg?updatedAt=1732773614466"

    //For page transition animation
    useEffect(()=>{
        if(passPrevFooterFlag){
        setCheck(true)
        setTimeout(()=>{
            setCheck(false)
        },100)
        }
    },[passPrevFooterFlag])

    //For smooth component render animation
    useEffect(()=>{
        if( (!passNavbarFlag && !passFooterFlag && !check)){
        setTimeout(()=>{
            setBlurBG(false)
        },400)
        }
        else if(check && !passFooterFlag){
        setBlurBG(false)
        }
        else {
        setBlurBG(true)
        }
    },[passNavbarFlag, passFooterFlag, check])

  return (
    <motion.div className="container template-container"
      animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
      transition={{duration:0.3}}>


        <h1 style={{color:`white`}}>Company Reports</h1>
        <br />

        <div className="cr-doc-btn-box"> 
            <a className="cr-doc-btn" 
                href={moaUrl}  
                target="_blank" 
                rel="noreferrer">
                    MOA
            </a>   
            {/* <div className="cr-doc-btn"></div>
            <div className="cr-doc-btn"></div>
            <div className="cr-doc-btn"></div> */}

        </div>
        {/* <div className="moa-box">
            <img src="https://ik.imagekit.io/qljqw3tvn/Rashi/MOA_cH8Q_nLUVp.jpg?updatedAt=1732773614466" alt="" />
        </div> */}
    </motion.div>
  )
}

export default CompanyReport