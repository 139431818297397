import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { useMediaQuery } from 'react-responsive' 
import Navbar from './components/Navbar/Navbar';

import Landing from './pages/LandingPage/Landing';

// import Artist from './pages/Portfolio/Artist';
import Corporate from './pages/Portfolio/Corporate';
import Goverment from './pages/Portfolio/Goverment';
import Social from './pages/Portfolio/Social';
import Sunburn from './pages/Portfolio/Sunburn';
import Wedding from './pages/Portfolio/Wedding';
import Media from './pages/Media/Media';
import Awards from './pages/Awards/Awards';

import PageNotFound from './pages/PageNotFound/PageNotFound';

import Template from './pages/Template/Template';
import FinalTemplate from './pages/Template/FinalTemplate';

import './App.css';
import NavbarMobile from './components/Navbar/NavbarMobile';
import CompanyReport from './pages/Company Report/CompanyReport';

function App() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  
  const [navbarFlag, setNavbarFlag] = useState(false)
  const [footerFlag, setFooterFlag] = useState(false)
  const [prevFooterFlag, setPrevFooterFlag] = useState()
  
  const [sliderFlag, setSliderFlag] = useState(false)
  const [imageList, setImageList] = useState([])
  const [currentImgIndex, setCurrentImgIndex] = useState(0)


  return (
    <>
    
    <AnimateSharedLayout type='crossfade'>
    <AnimatePresence>
    <Router>
      <div className='container'>
        {isSmallWindow? 
        <NavbarMobile 
          passNavbarFlag={setNavbarFlag} 
          passModalFlag={setFooterFlag}
          passPrevModalFlag={setPrevFooterFlag} 
          
          passImageSliderFlag={sliderFlag}
          passImageList={imageList}
          passCurrentImageIndex={currentImgIndex}
          />
        :
        <Navbar 
          passNavbarFlag={setNavbarFlag} 
          passModalFlag={setFooterFlag}
          passPrevModalFlag={setPrevFooterFlag} 
          
          passImageSliderFlag={sliderFlag}
          passImageList={imageList}
          passCurrentImageIndex={currentImgIndex}
          />}
        
          <Routes>
            <Route path='/' element={<Landing 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag} />} />

            
            <Route path='weddings' element={<Wedding 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}  />} />
            
            <Route path='social' element={<Social 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}   />} />
            
            <Route path='sunburn' element={<Sunburn 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}  />} />
            
            <Route path='corporate' element={<Corporate 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag} />} />
            
            {/* <Route path='/artist-mangement' element={<Artist 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag} />} /> */}
            
            <Route path='/government' element={<Goverment 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag} />} />

            <Route path='/media' element={<Media 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}  />} />

            <Route path='/awards' element={<Awards 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}  />} />

            <Route path='/company-report' element={<CompanyReport
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}
            /> }/>


            <Route path=':category/gallery' element={<Template 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}
              passImageSliderFlag={setSliderFlag}  />} />

            <Route path=':category/gallery/:subCategory' element={<FinalTemplate 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}
              passImageSliderFlag={setSliderFlag}
              passImageList={setImageList}
              passCurrentImageIndex={setCurrentImgIndex}
               />} />

            <Route path="*" element={<PageNotFound 
              passNavbarFlag = {navbarFlag}
              passFooterFlag={footerFlag}
              passPrevFooterFlag={prevFooterFlag}  />} />

          </Routes>
      </div>
    </Router>
    </AnimatePresence>
    </AnimateSharedLayout>
    </>
  );
}

export default App;
