const PathList= [
    {
        title: "Weddings",
        path: "weddings/gallery"
    },
    {
        title: "Social",
        path: "social/gallery"

    },
    {
        title: "Sunburn",
        path: "sunburn/gallery"
    },
    {
        title: "Corporate",
        path: "corporate/gallery"
    },
    // {
    //     title: "Artist Management",
    //     path: "artist-mangement"
    // },
    {
        title: "Government",
        path:"government/gallery"
    },
    {
        title: "Media",
        path:"media"
    },
    {
        title: "Awards",
        path:"awards"
    },
    {
        title:"Company Report",
        path:"company-report",
        // path:"https://ik.imagekit.io/qljqw3tvn/Rashi/MOA_cH8Q_nLUVp.jpg?updatedAt=1732773614466"
    }
    ]

export default PathList